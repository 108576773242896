import React from "react";
import { getSectionData } from "../../utils/extractConfigData";

import styles from "./Tickets.module.css";

const Tickets = () => {
  const sectionData = getSectionData("tickets");

  if (!sectionData.enabled) return "";

  return (
    <section id={sectionData.id} className={styles.section}>
      <div className={styles.container}>
        <div className={styles.content}>
          <p className={styles.title}>{sectionData.title}</p>
          {sectionData.text.map((paragraph, index) => (
            <p key={index} className={styles.text}>
              {paragraph}
            </p>
          ))}
          {sectionData.showTicketTypes && sectionData.tickets.length > 0 ? (
            <p className={styles.text}>
              <ul className={styles.ticketList}>
                {sectionData.tickets.map((ticket, index) => (
                  <li key={index}>
                    <span className={styles.ticketName}>{ticket.name}</span> {ticket.price}€ <span className={styles.ticketText}>{ticket.text}</span>
                  </li>
                ))}
              </ul>
            </p>
          ) : null}
          <p className={styles.link}>
            <a className={styles.button} href={sectionData.link} target="_blank" rel="noreferrer">
              Tickets Kopen
            </a>
          </p>
        </div>
      </div>
    </section>
  );
};

export default Tickets;
