import React, { useState } from "react";
import { useLoadScript, GoogleMap, Marker, InfoWindow } from "@react-google-maps/api";
import { getFestivalInformation, getMapData } from "../../utils/extractConfigData";

const containerStyle = {
  width: "100%",
  height: "488px",
};

const Map = () => {
  const festInfo = getFestivalInformation();
  const mapData = getMapData();

  const apiKey = mapData.apiKey;

  const { isLoaded, loadError } = useLoadScript({ googleMapsApiKey: apiKey });
  const [showInfoWindow, setShowInfoWindow] = useState(false);

  const scholfestival = {
    lat: mapData.position.lat,
    lng: mapData.position.lng,
  };

  const image = mapData.markerImage;

  const marker = {
    position: scholfestival,
    title: festInfo.title,
    address: festInfo.address,
    icon: image,
  };

  if (loadError) {
    return <div>There was an error loading the map</div>;
  }

  if (!isLoaded) {
    return <div>Loading...</div>;
  }

  const onMarkerLoad = () => {
    setShowInfoWindow(true);
  };

  const onMarkerClick = () => {
    setShowInfoWindow(true);
  };

  const onInfoWindowClose = () => {
    setShowInfoWindow(false);
  };

  return (
    <>
      <GoogleMap mapContainerStyle={containerStyle} center={scholfestival} zoom={17} apiKey={apiKey}>
        <Marker position={marker.position} icon={marker.icon} onLoad={onMarkerLoad} onClick={onMarkerClick} />
        {showInfoWindow && (
          <InfoWindow
            position={{
              lat: marker.position.lat,
              lng: marker.position.lng,
            }}
            options={{
              pixelOffset: new window.google.maps.Size(0, -35), // Set the y-axis offset to -45 pixels
            }}
            onCloseClick={onInfoWindowClose}
          >
            <div>
              <h3>{marker.title}</h3>
              <p>{marker.address}</p>
              <p>
                <a href="http://maps.google.com/maps?daddr=51.309246,4.954833" target="_blank" rel="noreferrer">
                  Navigeer
                </a>
              </p>
            </div>
          </InfoWindow>
        )}
      </GoogleMap>
    </>
  );
};

export default Map;
