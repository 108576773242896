import React from "react";
import "./styles.jsx";

const BurgerButton = ({ isOpen, setIsOpen, onToggle }) => {
  const handleClick = () => {
    const newIsOpen = !isOpen;

    if (setIsOpen)
      setIsOpen(newIsOpen);

    if (onToggle)
      onToggle(newIsOpen);
  };

  return (
    <div className={`burger-button ${isOpen ? "open" : ""}`} onClick={handleClick}>
      <span className="burger-span"></span>
      <span className="burger-span"></span>
      <span className="burger-span"></span>
      <span className="burger-span"></span>
    </div>
  );
};

export default BurgerButton;
