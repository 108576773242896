import React from "react";
import { Link } from "react-router-dom";

import styles from "./ProgramComponent.module.css";

const ProgramComponent = ({ data, className }) => {
  const { image } = data;
  const imagePath = `../../img/${image}`;

  const content = (
    <div className={styles.component}>
      <div className={styles.background}>
        <img src={imagePath} alt={image}/>
      </div>
      <div className={styles.header}>
        <div className={styles.headerDay}>{data.day}</div>
        <div className={styles.headerTitle}>{data.title}</div>
        <div className={styles.headerHour}>{data.hour}</div>
      </div>
      <div className={styles.text}>{data.text}</div>
      {data.button ? <div className={styles.button}>{data.button}</div> : null}
    </div>
  );

  // If a link is provided, wrap the content in a Link component
  if (data.link) {
    return (
      <Link
        className={className}
        to={data.link}
        style={{ textDecoration: "none" }}
      >
        {content}
      </Link>
    );
  }

  return <div className={className}>{content}</div>;
};

export default ProgramComponent;
