import React from "react";
import { Outlet } from "react-router-dom";

import Background from "../components/Background";
import Footer from "./Footer";
import Header from "./Header";

const PublicLayout = () => (
  <>
    <Background />
    <Header />
    <div style={{ position: "relative" }}>
      <Outlet />
    </div>
    <Footer />
  </>
);

export default PublicLayout;
