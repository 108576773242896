import React from "react";
import { Helmet } from "react-helmet";

const divStyle = {
  display: "flex",
  "flex-direction": "column",
  "align-items": "center",
};

const h1Style = {
  "text-transform": "uppercase",
  "font-size": "70px",
  "font-weight": "bold",
  color: "white",
  "margin-bottom": 0,
};

const h2Style = {
  "text-transform": "uppercase",
  "font-size": "50px",
  "font-weight": "bold",
  color: "white",
  "margin-top": "0",
};

const NotFoundPage = () => {
  return (
    <div style={divStyle}>
      <Helmet>
        <title>Not Found</title>
      </Helmet>
      <div>
        <h1 style={h1Style}>OEPS</h1>
        <h2 style={h2Style}>Deze pagina bestaat niet.</h2>
      </div>
    </div>
  );
};

export default NotFoundPage;
