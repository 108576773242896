import config from "../config.json";

/*function getSectionTitle(id) {
  const section = config.sections.filter(s => s.id === id)[0];
  return section ? section.title : '';
}

function getSectionText(id) {
  const section = config.sections.filter(s => s.id === id)[0];
  return section ? section.text : '';
}*/

// Function to extract main site data from config file
export const getFestivalInformation = () => {
  const info = {
    title: config.title,
    description: config.description,
    date: config.date,
    address: config.address,
    addressName: config.addressName,
    url: config.url,
    logo: config.logo
  };

  return info;
};

// Function to extract map data from config file
export const getMapData = () => {
  return config.map;
};

// Function to extract contact data from config file
export const getContactData = () => {
  return config.contact;
};

// Function to extract lineUp data from config file
export const getLineUpData = () => {
  return config.lineUp;
};

// Function to check if band with id exists
export const bandExists = (id) => {
  return config.lineUp.bands.some((band) => band.id === id && band.show);
}

// Function to check if band with id exists
export const getBandById = (id) => {
  return config.lineUp.bands.find((band) => band.id === id);
}

export const getNavItems = () => {
  const navItems = [];

  navItems.push({ to: "/", text: "Home" });

  if (config.lineUp.enabled) navItems.push({ to: "/line-up/#line-up", text: config.lineUp.title });

  const mappedSections = config.sections.filter((section) => section.enabled).map((section) => ({ to: "/#" + section.id, text: section.title }));

  navItems.push(...mappedSections);

  if (config.contact.enabled) navItems.push({ to: "/#contact", text: config.contact.title });

  return navItems;
};

export const isSectionEnabled = (id) => {
  return config.sections.filter(s => s.id === id)[0]?.enabled ? true : false;
}

export const getSectionData = (id) => {
  return config.sections.filter(s => s.id === id)[0];
}