import React from "react";
import { getBandById } from "../../utils/extractConfigData";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFacebook, faInstagram, faYoutube, faSpotify } from '@fortawesome/free-brands-svg-icons';

import styles from "./Band.module.css";

const Band = ({bandId}) => {
  const bandData = getBandById(bandId);

  const { id, name, text, facebook, instagram, youtube, spotify } = bandData;
  const imagePath = `../../img/${id}.jpg?v=2`;

  const hasLinks = facebook || instagram || youtube || spotify;

  return (
    <section id="band" className={styles.section}>
      <div className={styles.container}>
        <div className={styles.content}>
          <p className={styles.title}>{name}</p>
          <img className={styles.img} src={imagePath} alt={id} />
          {text.map((paragraph, index) => (
            <p key={index} className={styles.text}>
              {paragraph}
            </p>
          ))}
          {hasLinks && (
            <div className={styles.links}>
              {facebook && (
                <a href={facebook} className={styles.link} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faFacebook} size="2x" />
                </a>
              )}
              {instagram && (
                <a href={instagram} className={styles.link} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faInstagram} size="2x" />
                </a>
              )}
              {youtube && (
                <a href={youtube} className={styles.link} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faYoutube} size="2x" />
                </a>
              )}
              {spotify && (
                <a href={spotify} className={styles.link} target="_blank" rel="noopener noreferrer">
                  <FontAwesomeIcon icon={faSpotify} size="2x" />
                </a>
              )}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Band;
