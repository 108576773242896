import React, { useState } from "react";
import { HashLink } from "react-router-hash-link";
import { getNavItems, getFestivalInformation } from "../../utils/extractConfigData";

import BurgerButton from "../../components/BurgerButton";

import styles from "./Header.module.css";

const Header = () => {
  const [isMobileMenuOpen, setMobileMenuOpen] = useState(false);

  const festivalInformation = getFestivalInformation();

  function handleBurgerButtonToggle(newIsOpen) {
    setMobileMenuOpen(newIsOpen);
  }

  function handleNavLinkClick() {
    setMobileMenuOpen(false);
  }

  return (
    <header className={styles.header}>
      <div className={styles.headerBar} />
      <div className={styles.mobileOnly}>
        <BurgerButton isOpen={isMobileMenuOpen} setIsOpen={setMobileMenuOpen} onToggle={handleBurgerButtonToggle} />
        <div className={`${styles.mobileMenu} ${isMobileMenuOpen ? styles.open : ""}`}>
          <NavigationMenu onNavLinkClick={handleNavLinkClick} />
        </div>
      </div>
      <div className={styles.desktopOnly}>
        <NavigationMenu />
      </div>
      <div className={styles.logo}>
        <img src={festivalInformation.logo} alt="logo" />
        <div className={styles.caption}>{festivalInformation.date}</div>
      </div>
    </header>
  );
};

function NavigationMenu({ onNavLinkClick }) {
  const handleNavLinkClick = () => {
    if (typeof onNavLinkClick === "function") {
      onNavLinkClick();
    }
  };

  const navItems = getNavItems();

  return (
    <ul className={styles.navigationMenu}>
      {navItems.map((item, index) => (
        <li key={index}>
          <HashLink to={item.to} onClick={handleNavLinkClick}>
            {item.text}
          </HashLink>
        </li>
      ))}
    </ul>
  );
}

export default Header;
