import React from "react";
import { getContactData } from "../../utils/extractConfigData";

import styles from "./Contact.module.css";

const Contact = () => {
  const contactData = getContactData();

  if (!contactData.enabled) return "";

  return (
    <section id="contact" className={styles.section}>
      <div className={styles.title}>{contactData.title}</div>
      <div className={styles.content}>
        <a href={`mailto:${contactData.email}`}>
          <img src={contactData.emailImage} alt="email" />
        </a>
        <a href={contactData.facebook} target="_blank" rel="noreferrer">
          <img src={contactData.facebookImage} alt="facebook" />
        </a>
        <a href={contactData.instagram} target="_blank" rel="noreferrer">
          <img src={contactData.instagramImage} alt="instagram" />
        </a>
      </div>
    </section>
  );
};

export default Contact;
