import React from "react";
import { getSectionData } from "../../utils/extractConfigData";

import styles from "./About.module.css";

const About = () => {
  const sectionData = getSectionData("over-ons");

  if (!sectionData.enabled) return "";

  return (
    <section id={sectionData.id} className={styles.section}>
      <div className={styles.container}>
        <div className={styles.content}>
          <p className={styles.title}>{sectionData.title}</p>
          {sectionData.paragraphs.map((paragraph, index) => (
            <p key={index}>{paragraph}</p>
          ))}
        </div>
      </div>
    </section>
  );
};

export default About;
