import React from "react";
import GridLayouts from "../../utils/GridLayouts";

import styles from "./Grid.module.css";

const ImageGrid = ({ children, layout = GridLayouts.DEFAULT }) => {
  const childrenWithGridItemClass = React.Children.map(children, (child) => {
    return React.cloneElement(child, {
      className: styles.gridItem,
    });
  });

  return <div className={`${styles.grid} ${styles[layout]}`}>{childrenWithGridItemClass}</div>;
};

export default ImageGrid;
