import React from "react";

import Map from "../components/Map";
import Contact from "../components/Contact";

const Footer = () => {
  return (
    <>
      <Contact />
      <Map />
    </>
  );
};

export default Footer;
