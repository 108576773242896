import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useParams, useNavigate } from "react-router-dom";
import { bandExists, getBandById } from "../utils/extractConfigData";
import Band from "../components/Band";

const BandPage = () => {
  const { bandId } = useParams();
  const navigate = useNavigate();

  useEffect(() => {
    if (!bandExists(bandId)) {
      navigate("/notfound", { replace: true });
    }
  }, [bandId, navigate]);

  return (
    <div>
      <Helmet>
        <title>Band {getBandById(bandId).name}</title>
      </Helmet>
      <Band bandId={bandId} />
    </div>
  );
};

export default BandPage;
