import React from "react";
import { HashLink } from "react-router-hash-link";

import styles from "./BandComponent.module.css";

const BandComponent = ({ data, className }) => {
  if (!data.show) return null;

  const { id } = data;
  const imagePath = `../../img/${id}.jpg?v=2`;
  const link = `/band/${id}/#band`;

  const content = (
    <HashLink className={className} to={link} style={{ textDecoration: "none" }}>
      <div className={styles.component}>
        <div className={styles.background}>
          <img src={imagePath} alt={id} />
        </div>
        <div className={styles.header}>
          <div className={styles.title}>{data.name}</div>
        </div>
      </div>
    </HashLink>
  );

  return content;
};

export default BandComponent;
