import React from "react";
import { Helmet } from "react-helmet";
import LineUp from "../components/LineUp";

const LineUpPage = () => {
  return (
    <div>
      <Helmet>
        <title>LineUp</title>
      </Helmet>
      <LineUp />
    </div>
  );
};

export default LineUpPage;
