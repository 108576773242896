import React from "react";
import { Helmet } from "react-helmet";

import Program from "../components/Program";
import About from "../components/About";
import Tickets from "../components/Tickets";
import Faq from "../components/Faq";

const HomePage = () => {
  return (
    <div>
      <Helmet>
        <title>SchoL!Festival 2024</title>
      </Helmet>
      <Program />
      <About />
      <Tickets />
      <Faq />
    </div>
  );
};

export default HomePage;
