import React from "react";
import { getSectionData } from "../../utils/extractConfigData";

import styles from "./Faq.module.css";

const Faq = () => {
  const sectionData = getSectionData("faq");

  if (!sectionData.enabled) return "";

  return (
    <section id={sectionData.id} className={styles.section}>
      <div className={styles.container}>
        <div className={styles.content}>
          <p className={styles.title}>{sectionData.title}</p>
          {sectionData.items.map((item, index) => {
            const parts = item.answer.split("{link:");

            return (
              <>
                <p key={index + "q"} className={styles.subtitle}>
                  {item.question}
                </p>
                <p key={index + "a"} className={styles.text}>
                  {parts.map((part, pIndex) => {
                    if (pIndex !== 0) {
                      const [linkText, rest] = part.split("}");
                      if (item.link) {
                        return (
                          <React.Fragment key={pIndex}>
                            <a href={item.link} target="_blank" rel="noopener noreferrer" className={styles.link}>
                              {linkText}
                            </a>
                            {rest}
                          </React.Fragment>
                        );
                      } else {
                        return (
                          <React.Fragment key={pIndex}>
                            {linkText}
                            {rest}
                          </React.Fragment>
                        );
                      }
                    }
                    return part;
                  })}
                </p>
              </>
            );
          })}
        </div>
      </div>
    </section>
  );
};

export default Faq;
