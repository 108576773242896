import React from "react";
import { getLineUpData } from "../../utils/extractConfigData";
import Grid from "../Grid";
import GridLayouts from "../../utils/GridLayouts";
import BandComponent from "../BandComponent";

import styles from "./LineUp.module.css";

const LineUp = () => {
  const lineUpData = getLineUpData();

  if (!lineUpData.enabled) return "";

  const sortedBands = lineUpData.bands.sort((a, b) => {
    return a.order - b.order;
  });

  return (
    <section id="line-up" className={styles.section}>
      <div className={styles.container}>
        <div className={styles.content}>
          <p className={styles.title}>{lineUpData.title}</p>

          <Grid layout={GridLayouts.TWO_COLUMN}>
            {sortedBands.map((data, index) => (
              <BandComponent key={index} data={data} />
            ))}
          </Grid>
        </div>
      </div>
    </section>
  );
};

export default LineUp;
