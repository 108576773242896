import React from "react";
import { getSectionData } from "../../utils/extractConfigData";
import Grid from "../Grid";
import GridLayouts from "../../utils/GridLayouts";
import ProgramComponent from "../ProgramComponent";

import styles from "./Program.module.css";

const Program = () => {
  const programData = getSectionData("programma");

  if (!programData.enabled) return "";

  return (
    <section id="programma" className={styles.section}>
      <div className={styles.container}>
        <div className={styles.content}>
          <p className={styles.title}>{programData.title}</p>

          <Grid layout={GridLayouts.DEFAULT}>
            {programData.items.map((data, index) => (
              <ProgramComponent key={index} data={data} />
            ))}
          </Grid>
        </div>
      </div>
    </section>
  );
};

export default Program;
